import React, { Component } from 'react';

class Contact extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var message = this.props.data.contactmessage;
    }

    const redirect = () => {
      window.location = "mailto:tamiryoav@gmail.com"
    }

    return (
      <section id="contact">

         <h1>  Get In Touch.</h1>
         <button onClick={redirect}  className="submit">TamirYoav@Gmail.Com</button>


   </section>
    );
  }
}

export default Contact;
